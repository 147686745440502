import React, { useState, useEffect, useCallback } from 'react';
import LeftSidebar from './LeftSidebar';
import CenterPane from './CenterPane';
import RightPane from './RightPane';
import StylePickerPair from './components/stylepickerpair';
import AudioTrack from './components/audioTrack';
import UploadButton from './components/uploadButton';
import ProgressBar from './components/ProgressBar';
import TypingIndicator from './components/typingindicator';
import { Chat, User, Sample, Message, ChatFormData, ChatResponse, MessageResponse } from './types/chat';
import './ChatApp.css';
import './LeftSidebar.css';
import './RightPane.css';
import './CenterPane.css';

const DEFAULT_AVATAR = '/assets/images/max_martin.png';
const MAX_CHATS_DISPLAYED = 10;
const API_BASE_URL = 'https://platform.session42.xyz';
const TYPING_DURATION = 3000;

const ChatApp: React.FC = () => {
  const [chats, setChats] = useState<Chat[]>([]);
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isChatLoading, setChatIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | null>(null);
  const [samples, setSamples] = useState<Sample[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [showChatForm, setShowChatForm] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [currentFile, setCurrentFile] = useState<string | null>(null);
  const [fileUploadComplete, setFileUploadComplete] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    fetchChats();
  }, []);

  useEffect(() => {
    if (selectedChat) {
      fetchUserData(selectedChat.id);
      fetchSamples(selectedChat.id);
      fetchMessages(selectedChat.id);
    } else {
      setUser(null);
      setSamples([]);
      setMessages([]);
    }
  }, [selectedChat]);

  const fetchChats = async (): Promise<void> => {
    setChatIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/chat`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch chats');
      }
      
      const chatsData: ChatResponse = await response.json();

      const formattedChats: Chat[] = await Promise.all(
        chatsData.chats.map(async (chat) => {
          const imageUrl = `https://platform.session42.xyz/chat/${chat.id}/image`;
          
          try {
            const response = await fetch(imageUrl);
            const imageExists = response.ok;

            const imageData = await response.json();

            console.log('imageExists: ', imageExists);
            console.log('imageData: ', imageData);
      
            return {
              id: chat.id,
              name: chat.name,
              avatar: imageExists ? imageData : DEFAULT_AVATAR,
              lastMessage: chat.lastMessage,
              timestamp: chat.timestamp ? new Date(chat.timestamp) : undefined,
            };
          } catch (error) {
            console.error(`Failed to load image for chat ID ${chat.id}:`, error);
            return {
              id: chat.id,
              name: chat.name,
              avatar: DEFAULT_AVATAR,
              lastMessage: chat.lastMessage,
              timestamp: chat.timestamp ? new Date(chat.timestamp) : undefined,
            };
          }
        })
      );
      
      // const formattedChats: Chat[] = chatsData.chats.map(chat => ({
      //   id: chat.id,
      //   name: chat.name,
      //   avatar: chat.avatar || DEFAULT_AVATAR,
      //   lastMessage: chat.lastMessage,
      //   timestamp: chat.timestamp ? new Date(chat.timestamp) : undefined,
      // }));
      
      setChats(formattedChats);
      setChatIsLoading(false);
      console.log('Chats fetched:', formattedChats);
      
      if (formattedChats.length > 0) {
        handleSelectChat(formattedChats[0]);
      }
    } catch (error) {
      console.error('Error fetching chats:', error);
      setChatIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectChat = useCallback((chat: Chat) => {
    console.log('Chat selected:', chat);
    setSelectedChat(chat);
  }, []);

  const handleCreateNewChat = useCallback(async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/chat/new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: 'New Chat',
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create chat');
      }

      const newChat = await response.json();
      console.log('New chat created:', newChat);
      setChats(prev => [...prev, newChat]);
      setSelectedChat(newChat);
      setShowChatForm(true);
    } catch (error) {
      console.error('Error creating chat:', error);
    }
  }, []);

  const handleRequestProduceSong = useCallback(async () => {
    setIsTyping(true);
    
    // Initial typing indicator
    const initialMessage: Message = {
      content: <TypingIndicator />,
      isRightSide: false
    };
    setMessages(prev => [...prev, initialMessage]);

    // Wait for typing duration
    await new Promise(resolve => setTimeout(resolve, TYPING_DURATION));

    // Replace typing indicator with actual message
    const actualMessage: Message = {
      content: "Let's produce your sketch",
      isRightSide: false
    };
    setMessages(prev => [...prev.slice(0, -1), actualMessage]);
    
    setIsTyping(true);
    // Second typing indicator
    const loadingMessage: Message = {
      content: <TypingIndicator />,
      isRightSide: false
    };
    setMessages(prev => [...prev, loadingMessage]);

    // Wait for typing duration
    await new Promise(resolve => setTimeout(resolve, TYPING_DURATION));

    // Remove typing indicator and add upload component
    const uploadComponent: Message = {
      content: 'Please upload your sketch',
      // content: <UploadButton onUpload={(file) => handleFileUpload(file)} />,
      isRightSide: false
    };
    setMessages(prev => [...prev.slice(0, -1), uploadComponent]);
    setIsTyping(false);

    setShowForm(true);
  }, []);

  const handleFileUpload = async (file: File) => {
    if (!selectedChat) return;

    console.log('File upload started:', file);

    const progressMessage: Message = {
      content: <ProgressBar title="Uploading your sketch" progress={0} />,
      isRightSide: false,
      isProgress: true
    };
    setMessages(prev => [...prev.filter(m => !m.isProgress), progressMessage]);

    let progress = 0;
    const progressInterval = setInterval(() => {
      progress = Math.min(progress + 10, 90);
      setMessages(prev =>
        prev.map((msg, index) =>
          index === prev.length - 1 && msg.isProgress
            ? { ...msg, content: <ProgressBar title="Uploading your sketch" progress={progress} /> }
            : msg
        )
      );
    }, 1000); // Updates progress every 3 seconds

    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

    // Wait for delay but let the interval run independently
    await delay(5000); // 30 seconds delay

    clearInterval(progressInterval);

    // const formData = new FormData();
    // formData.append("file", file);
    // formData.append("file_name", file.name);
    // formData.append("user_id", "ido@session-42.com");
    // formData.append("producer", "Cham");

    console.log('Uploading file:', file.name);
    let audioFile = '';

    let FileProceedUrl = 'mock-butcher';
    let fullUrl = `${API_BASE_URL}/file/${FileProceedUrl}?prompt=pop`;

    let showStylePicker = false;
    let isRealFlow = false;
    if (file.name === 'Artificial.mp3') {
      showStylePicker = true;
      FileProceedUrl = 'mock-butcher';
    }
    else if (file.name === 'Ed Sheeran - Shape of You.mp3'){
      FileProceedUrl = 'mock-dj-butcher';
      fullUrl = `${API_BASE_URL}/file/${FileProceedUrl}?prompt=pop`;
    }
    else if (file.name === 'Yann Selka - HOWL.mp3'){
      FileProceedUrl = `/file/fake-audio/${selectedChat.id}`;
      fullUrl = `${API_BASE_URL}${FileProceedUrl}`;
    } else {
      FileProceedUrl = `/file/upload`;
      fullUrl = `${API_BASE_URL}${FileProceedUrl}`;
      isRealFlow = true;
    }

    try {
      let data: any;
      if (!isRealFlow) {
        const response = await fetch(fullUrl, {
          method: 'GET',
          // body: formData,
        });
        // const response = await fetch(`${API_BASE_URL}/file/mock-butcher`, {
        //   method: 'POST',
        //   body: formData,
        // });

        clearInterval(progressInterval);

        if (!response.ok) {
          throw new Error('Failed to upload file');
        }

        data = await response.json();
        console.log('Mock answer:', data);
        audioFile = data.url;
      } else {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("file_name", file.name);
        formData.append("user_id", "ido@session-42.com");
        // formData.append("producer", "Cham");
        formData.append("producer", selectedChat.id);
        const response = await fetch(`${fullUrl}`, {
          method: 'POST',
          body: formData,
        });

        const data1 = await response.json();
        const { bpm, sketch_id } = data1;
        console.log('answer:', data1);

        const formData2 = new FormData();
        formData2.append("sketch_id", sketch_id);
        formData2.append("bpm", bpm);
        // formData2.append("producer", "Cham");
        formData2.append("producer", selectedChat.id);

        const response2 = await fetch(`${API_BASE_URL}/file/butcher`, {
          method: 'POST',
          body: formData2,
        });
        data = await response2.json();
        console.log('answer:', data);
      }

      // Update progress bar to 100%
      setMessages(prev => prev.map((msg, index) => 
        index === prev.length - 1 && msg.isProgress
          ? { ...msg, content: <ProgressBar title="Uploading your sketch" progress={100} /> }
          : msg
      ));

      // Wait a moment to show completed progress
      await new Promise(resolve => setTimeout(resolve, 1000));

      // Show typing indicator before final message
      setIsTyping(true);
      const typingMessage: Message = {
        content: <TypingIndicator />,
        isRightSide: false,
        isProgress: true
      };
      setMessages(prev => [...prev.filter(m => !m.isProgress), typingMessage]);

      // Wait for typing duration
      await new Promise(resolve => setTimeout(resolve, TYPING_DURATION));

      setMessages(prev => [
        ...prev.filter(m => !m.isProgress),
        showStylePicker
          ? {
              content: (
                <StylePickerPair 
                  styles={[
                    { id: '1', label: 'Pop', genre: 'Modern', isSelected: true },
                    { id: '2', label: 'Rock', genre: 'Classic', isSelected: false }
                  ]}
                  onStyleSelect={async (id) => {
                    console.log('Style selected:', id);
                    
                    const progressMessage: Message = {
                      content: <ProgressBar title="Progress Demo" progress={0} />,
                      isRightSide: false,
                      isProgress: true
                    };
                    setMessages(prev => [...prev.filter(m => !m.isProgress), progressMessage]);

                    let progress = 0;
                    const progressInterval = setInterval(() => {
                      progress = Math.min(progress + 10, 90);
                      setMessages(prev =>
                        prev.map((msg, index) =>
                          index === prev.length - 1 && msg.isProgress
                            ? { ...msg, content: <ProgressBar title="Progress Demo" progress={progress} /> }
                            : msg
                        )
                      );
                    }, 3000); // Updates progress every 3 seconds

                    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

                    // Wait for delay but let the interval run independently
                    await delay(30000); // 30 seconds delay
                    clearInterval(progressInterval);

                    // Add success message and audio player
                    const successMessage: Message = {
                      // content: data.url,
                      content: audioFile,
                      isRightSide: false,
                      isMusic: true
                    };
                    setMessages(prev => [...prev.slice(0, -1), successMessage]);
                    setIsTyping(false);
                  }}
                />
              ),
              isRightSide: false
            }
          : {
              // Render the immediate result if showStylePicker is false
              content: audioFile,
              isRightSide: false,
              isMusic: true
            }
      ]);

      // setMessages(prev => [...prev.filter(m => !m.isProgress), {
      //   content: <StylePickerPair 
      //     styles={[
      //       { id: '1', label: 'Pop', genre: 'Modern', isSelected: true },
      //       { id: '2', label: 'Rock', genre: 'Classic', isSelected: false }
      //     ]}
      //     onStyleSelect={async (id) => {
      //       console.log('Style selected:', id);
      //       const progressMessage: Message = {
      //         content: <ProgressBar title="Progress Demo" progress={0} />,
      //         isRightSide: false,
      //         isProgress: true
      //       };
      //       setMessages(prev => [...prev.filter(m => !m.isProgress), progressMessage]);
      //       let progress = 0;
      //       const progressInterval = setInterval(() => {
      //         progress = Math.min(progress + 10, 90);
      //         setMessages(prev =>
      //           prev.map((msg, index) =>
      //             index === prev.length - 1 && msg.isProgress
      //               ? { ...msg, content: <ProgressBar title="Progress Demo" progress={progress} /> }
      //               : msg
      //           )
      //         );
      //       }, 3000); // Updates progress every 3 seconds
        
      //       const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));
        
      //       // Wait for delay but let the interval run independently
      //       await delay(30000); // 30 seconds delay
      //       clearInterval(progressInterval);

      //       // Add success message and audio player
      //       const successMessage: Message = {
      //         content: data.url,
      //         isRightSide: false,
      //         isMusic: true
      //       };
      //       setMessages(prev => [...prev.slice(0, -1), successMessage]);
      //       setIsTyping(false);
      //     }}
      //   />,
      //   isRightSide: false
      // }]);

      console.log('123');

      // Add success message and audio player
      // const successMessage: Message = {
      //   content: data.url,
      //   isRightSide: false,
      //   isMusic: true
      // };
      // setMessages(prev => [...prev.slice(0, -1), successMessage]);
      setIsTyping(false);

    } catch (error) {
      clearInterval(progressInterval);
      console.error('Error uploading file:', error);
      handleMockAnswer('Failed to upload file');
    }
  };

  const handleSendMessage = useCallback(async (message: string, mock = false) => {
    if (!selectedChat || !message.trim()) return;

    console.log('Sending message:', message);

    // Handle the "invoke" command
    if (message.toLowerCase() === 'invoke') {
      setMessages([
        {
          content: <ProgressBar title="Progress Demo" progress={75} />,
          isRightSide: false,
          isProgress: true
        },
        {
          content: <AudioTrack 
            trackNumber={1} 
            audioSrc=''
            onPlay={() => console.log('Play')} 
            onPause={() => console.log('Pause')}
            onDownload={() => console.log('Download')}
            onForward={() => console.log('Forward')}
            onShare={() => console.log('Share')}
            onInfo={() => console.log('Info')}
            onProgressChange={(progress) => console.log('Progress:', progress)}
          />,
          isRightSide: false,
          isPlayer: true
        },
        {
          content: <UploadButton onUpload={(file) => console.log('File selected:', file)} />,
          isRightSide: false
        },
        {
          content: <StylePickerPair 
            styles={[
              { id: '1', label: 'Pop', genre: 'Modern', isSelected: true },
              { id: '2', label: 'Rock', genre: 'Classic', isSelected: false }
            ]}
            onStyleSelect={(id) => console.log('Style selected:', id)}
          />,
          isRightSide: false
        }
      ]);
      return;
    }

    const newMessage: Message = {
      content: message,
      isRightSide: true
    };

    // Add user's message immediately
    if (!mock) {
      setMessages(prev => [...prev, newMessage]);
    }

    // Show typing indicator
    setIsTyping(true);
    const typingMessage: Message = {
      content: <TypingIndicator />,
      isRightSide: false
    };
    setMessages(prev => [...prev, typingMessage]);

    if (mock) {
      // Wait for typing duration
      await new Promise(resolve => setTimeout(resolve, TYPING_DURATION));
      
      const mockResponse: Message = {
        content: 'Producing your song now!',
        isRightSide: false
      };
      setMessages(prev => [...prev.slice(0, -1), mockResponse]);
      setIsTyping(false);
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/chat/${selectedChat.id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
      });

      // Wait for typing duration while the API processes
      await new Promise(resolve => setTimeout(resolve, TYPING_DURATION));

      if (!response.ok) {
        throw new Error('Failed to send message');
      }

      const responseData = await response.json();
      console.log('Response data:', responseData);

      const newAssistantMessage: Message = {
        content: responseData.response.content?.[0].text,
        isRightSide: false
      };

      setMessages(prev => [...prev.slice(0, -1), newAssistantMessage]);
      setIsTyping(false);

      const updatedChats = chats.map(chat =>
        chat.id === selectedChat.id
          ? { ...chat, lastMessage: message, timestamp: new Date() }
          : chat
      );
      
      setChats(updatedChats.sort((a, b) => 
        (b.timestamp?.getTime() || 0) - (a.timestamp?.getTime() || 0))
      );

    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prev => prev.slice(0, -1));
      setIsTyping(false);
    }
  }, [selectedChat, chats]);

  const handleMockAnswer = useCallback((message: string) => {
    setIsTyping(true);
    
    const typingMessage: Message = {
      content: <TypingIndicator />,
      isRightSide: false
    };
    setMessages(prev => [...prev.filter(m => !m.isProgress), typingMessage]);

    // Wait for typing duration
    setTimeout(() => {
      const newAssistantMessage: Message = {
        content: message,
        isRightSide: false,
        isMusic: true
      };
      console.log('New assistant message:', newAssistantMessage);
      setMessages(prev => [...prev.slice(0, -1), newAssistantMessage]);
      setIsTyping(false);
    }, TYPING_DURATION);
  }, []);

  const fetchUserData = async (userId: string): Promise<void> => {
    try {
      const response = await fetch(`${API_BASE_URL}/chat/${userId}/user`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      
      const userData: User = await response.json();
      setUser(userData);
      console.log('User data fetched:', userData);
    } catch (error) {
      console.error('Error fetching user data:', error);
      const mockUser: User = {
        id: userId,
        name: selectedChat?.name || '',
        avatar: DEFAULT_AVATAR,
        title: 'Music Producer',
      };
      setUser(mockUser);
    }
  };

  const fetchSamples = async (userId: string): Promise<void> => {
    try {
      const response = await fetch(`${API_BASE_URL}/chat/${userId}/samples`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch samples');
      }
      
      const samplesData: Sample[] = await response.json();
      setSamples(samplesData);
      console.log('Samples fetched:', samplesData);
    } catch (error) {
      console.error('Error fetching samples:', error);
      const mockSamples: Sample[] = [
        { name: 'Unicorn', listens: '94K' },
        { name: 'Dreamland', listens: '78K' },
        { name: 'Stardust', listens: '62K' },
      ];
      setSamples(mockSamples);
    }
  };

  const fetchMessages = async (chatId: string): Promise<void> => {
    setChatIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/chat/${chatId}/history`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch messages');
      }
      
      const messagesData: MessageResponse = await response.json();
      const formattedMessages: Message[] = messagesData.messages.map(msg => ({
        content: typeof msg.content === 'string' ? msg.content : msg.content[0].text,
        isRightSide: msg.role === 'user',
      }));

      console.log({
        messagesData,
        formattedMessages,
      });
      
      setMessages(formattedMessages);
      console.log('Messages fetched:', formattedMessages);
    } catch (error) {
      console.error('Error fetching messages:', error);
      const mockMessages: Message[] = [
        { content: "Hello! I'm ready to help with your music production.", isRightSide: false },
        { content: "Can you help me produce a new song?", isRightSide: true },
      ];
      setMessages(mockMessages);
    } finally {
      setChatIsLoading(false);
    }
  };

  const onChatFormSubmitted = useCallback(async (formData: ChatFormData) => {
    if (!selectedChat) return;
    
    try {
      const response = await fetch(`${API_BASE_URL}/chat/${selectedChat.id}/form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      const responseData = await response.json();
      console.log('Form submitted:', responseData);
      setShowChatForm(false);
      
      const newMessage: Message = {
        content: `Form submitted: ${formData.title}`,
        isRightSide: true,
      };
      setMessages(prev => [...prev, newMessage]);

      // Show typing indicator after form submission
      setIsTyping(true);
      const typingMessage: Message = {
        content: <TypingIndicator />,
        isRightSide: false
      };
      setMessages(prev => [...prev, typingMessage]);

      // Wait for typing duration
      await new Promise(resolve => setTimeout(resolve, TYPING_DURATION));

      // Replace typing indicator with response
      const responseMessage: Message = {
        content: "Thank you for submitting the form. Let's get started!",
        isRightSide: false
      };
      setMessages(prev => [...prev.slice(0, -1), responseMessage]);
      setIsTyping(false);

    } catch (error) {
      console.error('Error submitting form:', error);
      setShowChatForm(false);
    }
  }, [selectedChat]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const sortedChats = chats
    // .filter(chat => ['Max Martin', 'Chamillionaire'].includes(chat.id))
    .slice(0, MAX_CHATS_DISPLAYED)
    .sort((a, b) => (b.timestamp?.getTime() || 0) - (a.timestamp?.getTime() || 0));

  // @ts-ignore
  const userAvatar = !selectedChat?.avatar.image_data ? `${selectedChat.avatar}` : `data:image/png;base64,${selectedChat?.avatar?.image_data}`;
  return (
    <div className="chat-app">
      <LeftSidebar 
        chats={sortedChats}
        selectedChatId={selectedChat?.id}
        onSelectChat={handleSelectChat}
        onCreateNewChat={handleCreateNewChat}
      />
      
      <CenterPane
        selectedUser={{
          ...user,
          avatar: userAvatar,
        }}
        selectedChatId={selectedChat?.id}
        showChatForm={showChatForm}
        onChatFormSubmitted={onChatFormSubmitted}
        messages={messages}
        onSendMessage={handleSendMessage}
        onHandleMockAnswer={handleMockAnswer}
        onFileUpload={handleFileUpload}
        isTyping={isTyping}
        onShowForm={handleRequestProduceSong}
      />

      {user && (
        <RightPane
          user={{
            ...user,
            avatar: userAvatar,
          }}
          samples={samples}
          selectedChatId={selectedChat?.id}
          onCreateNewChat={handleCreateNewChat}
          onShowForm={handleRequestProduceSong}
        />
      )}
    </div>
  );
};

export default ChatApp;